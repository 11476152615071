<template>
  <div class="page page-account">
    <b-col class="py-3">
      <GameHeader :page-name="$t('Buy diamond')"/>
      <RowLine />
      <div class="game-feature">
        <h1 class="feature-title">{{$t("Select a diamond package")}}</h1>
        <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idServer" :options="servers" value-field="id" text-field="name" :disabled="loading || formData.idGame < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idRole" :options="roles" value-field="id" text-field="name" :disabled="loading || formData.idServer < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.money" :options="moneys"  value-field="id" text-field="name" :disabled="loading || formData.idRole < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <b-button type="submit" variant="primary" block size="lg" class="font-weight-bold" :disabled="loading">
              {{ $t("Buy a diamond package") }}
            </b-button>
          </b-form-group>
          <b-form-group class="pt-2">
            <div class="font-weight-bold">
              1. Diamonds are the most valuable currency in the game, used to buy most of the in-game items.
            </div>
            <div>
              2. After buying diamonds will be added directly to the number of diamonds in your account in the game.
            </div>
            <div class="font-weight-bold text-danger">
              3. Buying diamonds will increase VIP points and deposit-related events (For example, accumulative events, first deposit...)
            </div>
            <div>
              4. Once purchased, you will not be able to return, please consider and choose the right character to buy!
            </div>
          </b-form-group>
        </ValidationObserver>
        <LoadingModal :show="loading"/>
      </div>
    </b-col>
  </div>
</template>

<script>
import GameHeader from "@/components/headers/GameHeader";
import serverGameService from "@/services/serverGameService";
import buyDiamondService from "@/services/buyDiamondService";
import roleService from "@/services/roleService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "Index",
  components: {GameHeader},
  data() {
    return {
      loading: false,
      servers: [{
        id: 0,
        name: this.$t("Select a server")
      }],
      roles: [{
        id: 0,
        name: this.$t("Select a role")
      }],
      moneys: [{
        id: 0,
        name: this.$t("Select a diamond value")
      }],
      formData: {
        idGame: 0,
        idServer: 0,
        idRole: 0,
        money: 0
      }
    }
  },
  created() {
    this.$bridge.$on("setCurrentGame", this.onSetGame);
  },
  beforeDestroy() {
    this.$bridge.$off("setCurrentGame", this.onSetGame);
  },
  methods: {
    onSetGame(game) {
      if (!game)
      {
        this.$gotoHomePage();
        return;
      }
      
      this.formData.idGame = game.id;
      this.loadMoneys(game.id);
    },
    async loadMoneys(idGame) {
      this.loading = true;
      const response = await buyDiamondService.getRules({
        idGame: idGame
      }) ;

      if (!response)
      {
        this.loading = false;
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        await this.$gotoHomePage();
        return;
      }
      
      if (response.error)
      {
        this.loading = false;
        await dialogHelper.alert(response.message);
        await this.$gotoHomePage();
        return;
      }
        
      this.moneys = [...this.moneys, ...response.data];      
      await this.loadServers(idGame);
    },
    async loadServers(idGame) {
      let servers = [{
        id: 0,
        name: this.$t("Select a server")
      }];
      
      const response = await serverGameService.getAllByGame(idGame);
      this.loading = false;
      if (!response)
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
      else if (response.error)
        await dialogHelper.alert(response.message);
      else
        servers = [...servers, ...response.data];      

      this.servers = servers;
      this.formData.idServer = servers.length > 1 ? servers[1].id : 0;
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await buyDiamondService.buy(this.formData, await this.$recaptcha('buyDiamond'))
      this.loading = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await dialogHelper.alert(response.message, "success");
      this.formData.idRole = 0;
    }
  },
  watch: {
    "formData.idServer": async function (newValue) {
      let roles = [{
        id: 0,
        name: this.$t("Select a role")
      }];

      if (newValue > 0) {
        this.loading = true;
        const response = await roleService.getRolesByServer(newValue);
        this.loading = false;
        if (!response)
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        else if (response.error)
          await dialogHelper.alert(response.message);
        else
          roles = [...roles, ...response.data];
      }

      this.roles = roles;
      this.formData.idRole = roles.length > 1 ? roles[1].id :  0;
    },
  }
}
</script>

<style scoped>

</style>