import httpHelper from "@/helpers/httpHelper";

const buyDiamondService = {
    async getRules(data) {
        return await httpHelper.get("/WebApi/BuyDiamond/GetRules", data);
    },
    async buy(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/BuyDiamond/Buy", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
};

export default buyDiamondService;